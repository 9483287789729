import { Button } from 'antd';
import { styled } from 'styled-components';

export default styled(Button)`
    &.ant-btn {
        height: auto;

        &.success {
            background-color: #f4fbf7;
            border: 1px solid #27ae60;
            color: #27ae60;
        }

        &.primary {
            color: #fff;
            background-color: var(--color-primary);
            border: 1px solid var(--color-primary);
        }

        &.danger {
            border: 1px solid #ff443b;
            color: #ff443b;
            background-color: #fff5f5;
        }

        &.secondary {
            color: #fff;
            background-color: var(--color-dark-purple);
            border: 1px solid var(--color-dark-purple);

            &:hover {
                background-color: #482c66;
                border-color: #482c66;
                color: #fff;
            }
        }

        &.ant-btn-lg {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }
`;
