import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserFullProfile } from '../../types/account';
import { revertAll } from '../actions/common';

export interface ProfileSlice {
    userProfile: UserProfile | undefined;
}

const initialState: ProfileSlice = {
    userProfile: {
        firstName: '',
        lastName: '',
        pin: '',
        email: '',
        userId: '',
        locale: '',
        entrepreneurAccount: '',
        isTest: false,
        name: '',
    },
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setProfile: (state, action: PayloadAction<Partial<UserFullProfile>>) => {
            const {
                first_name,
                last_name,
                user_id,
                profile_picture,
                work_address,
                locale = '',
                entrepreneur_account,
                pin,
                email,
                is_test,
                ...rest
            } = action.payload;
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    firstName: first_name ?? '',
                    lastName: last_name ?? '',
                    pin: pin ?? '',
                    email: email ?? '',
                    userId: user_id ?? '',
                    profilePicture: profile_picture,
                    workAddress: work_address,
                    locale,
                    entrepreneurAccount: entrepreneur_account,
                    isTest: is_test ?? false,
                    name: `${first_name} ${last_name}`,
                    ...rest,
                },
            };
        },
        setProfilePicture: (state, action: PayloadAction<string | undefined>) => {
            if (state.userProfile) {
                state.userProfile.profilePicture = action.payload;
            }
            return state;
        },
        reset: () => initialState,
    },
});

export const { setProfile, setProfilePicture, reset } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;

export interface UserProfile {
    firstName: string;
    lastName: string;
    pin: string;
    email: string;
    userId: string;
    workAddress?: string;
    occupation?: string;
    phone?: string;
    description?: string;
    alias?: string;
    profilePicture?: string;
    locale: string;
    entrepreneurAccount?: string;
    isTest: boolean;
    name: string;
}
