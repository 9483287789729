import { MenuOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import Wrapper from './styles';
import { Drawer } from 'antd';
import { LanguagesMenu } from '../languages/Languages';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../store/store';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { getRoute } from '../../../../utils/browser';
import { UiImage } from '../../../../ui/image/UiImage';
import classNames from 'classnames';
import { removeToken } from '../../../../utils/token';
import { setIsAuthenticated } from '../../../../store/slices/authSlice';
import { UiButton } from '../../../../ui/button/UiButton';
import { useTranslation } from '../../../../hooks/use-translation';
import { getRedirectLink } from '../helper';

export function MenuMobile() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { translate } = useTranslation();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const resolved = useResolvedPath(window.location);
    const match = useMatch({ path: resolved.pathname, end: true });
    const navigate = useNavigate();

    const loggedOutMenuItems = [
        {
            label: translate('How it works', 'header'),
            url: getRoute('#how-it-works'),
        },
        {
            label: translate('For whom', 'header'),
            url: getRoute('#who-needs-it'),
        },
        {
            label: translate('Tips', 'header'),
            url: getRoute('#tips'),
        },
        {
            label: translate('Prices', 'header'),
            url: getRoute('#prices'),
        },
    ];

    const loggedInMenuItems = [
        {
            label: translate('Profile', 'header'),
            url: getRoute('profile'),
        },
        {
            label: translate('Templates', 'header'),
            url: getRoute('templates'),
        },
    ];

    function navigateOnClick(to: string) {
        navigate(to);
        setIsMenuOpen(false);
    }

    function logout() {
        removeToken();
        store.dispatch(setIsAuthenticated(false));
        navigate('/');
        setIsMenuOpen(false);
    }

    return (
        <Wrapper>
            <Link to={getRedirectLink(isAuthenticated)}>
                <UiImage src="assets/logo" />
            </Link>
            <MenuOutlined onClick={() => setIsMenuOpen(true)} />
            <Drawer
                placement="right"
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                contentWrapperStyle={{ width: '100%', maxWidth: 'inherit' }}
                styles={{ header: { justifyContent: 'flex-end' } }}
                className="drawer-menu"
            >
                <Link to="/">
                    <UiImage src="assets/logo" className="logo" />
                </Link>
                {!isAuthenticated && (
                    <>
                        {loggedOutMenuItems.map((item) => {
                            return (
                                <UiButton
                                    key={item.url}
                                    type="link"
                                    onClick={() => navigateOnClick(item.url)}
                                    className={classNames({ active: match?.pathname + window.location.hash === item.url })}
                                >
                                    {item.label}
                                </UiButton>
                            );
                        })}

                        <UiButton onClick={() => navigateOnClick(getRoute('login'))} type="primary" size="large">
                            {translate('Join', 'header')}
                        </UiButton>
                        <UiButton onClick={() => navigateOnClick(getRoute('login'))} className="login-btn">
                            {translate('Sign Up', 'header')} / {translate('Login', 'header')}
                        </UiButton>
                    </>
                )}
                {isAuthenticated && (
                    <>
                        {loggedInMenuItems.map((item) => {
                            return (
                                <UiButton
                                    key={item.url}
                                    type="link"
                                    onClick={() => navigateOnClick(item.url)}
                                    className={classNames({ active: match?.pathname + window.location.hash === item.url })}
                                >
                                    {item.label}
                                </UiButton>
                            );
                        })}
                        <UiButton onClick={() => navigateOnClick(getRoute('account/withdraw'))} type="primary" size="large">
                            {translate('Withdraw', 'header')}
                        </UiButton>
                        <UiButton onClick={logout} size="large">
                            {translate('Logout', 'header')}
                        </UiButton>
                    </>
                )}

                <div className="languages">
                    <LanguagesMenu />
                </div>
            </Drawer>
        </Wrapper>
    );
}
