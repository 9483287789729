import axios from 'axios';
import { getTokens, removeToken } from './token';
import { refreshToken } from '../api/auth';

export const apiBaseUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:8080'; // === 'prod' ? '/s/api' : 'http://localhost:8000';
const instance = axios.create({
    baseURL: apiBaseUrl,
});

instance.interceptors.request.use(function (config) {
    const tokens = getTokens();
    if (config.url !== '/auth/refresh' && Object.keys(tokens).length > 0) {
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    return config;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error?.response?.data?.code === 1401) {
            removeToken();
            window.location.href = '/';
        } else if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshToken();
            const { accessToken } = getTokens();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            return instance(originalRequest);
        }
        return Promise.reject(error);
    },
);

export default instance;
