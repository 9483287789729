import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Container from '../../../components/container/Container';
import { Alert, App, Card, Form, InputNumber, Typography } from 'antd';
import { useTranslation } from '../../../hooks/use-translation';
import { UiButton } from '../../../ui/button/UiButton';
import { useForm, useWatch } from 'antd/es/form/Form';
import { createWithdrawRequest } from '../../../api/withdraw';
import { getBalances } from '../../../api/wallet/wallet';
import { Balances } from '../../../api/wallet/types';

const { Title } = Typography;
const SERVICE_FEE_PERCENTAGE = 8;

export function WithdrawPage() {
    const { translate } = useTranslation();
    const [availableBalance, setAvailableBalance] = useState<Balances>({
        balance: 0,
        pending_balance: 0,
    });
    const [form] = useForm();
    const amountToWithdraw = useWatch('amount', form);
    const [serviceFee, setServiceFee] = useState(0);
    const [totalPayout, setTotalPayout] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { message } = App.useApp();

    useEffect(() => {
        getUserBalance();
    }, []);

    useEffect(() => {
        form.setFieldValue('amount', availableBalance.balance);
        calculatePayout(availableBalance.balance);
    }, [availableBalance]);

    async function getUserBalance() {
        const balances = await getBalances();
        if (balances) {
            setAvailableBalance(balances);
        }
    }

    async function calculatePayout(amount: number | string | null) {
        if (typeof amount !== 'number') {
            setServiceFee(0);
            setTotalPayout(0);
            return;
        }

        try {
            await form.validateFields();
        } catch (err) {
            setIsButtonDisabled(true);
            return;
        }
        if (amount > availableBalance.balance) {
            form.setFields([
                {
                    name: 'amount',
                    errors: [`You cannot withdraw more than ${availableBalance.balance}`],
                },
            ]);
            return;
        }

        if (amount < 5) {
            form.setFields([
                {
                    name: 'amount',
                    errors: [translate(`Minimum amount is 5 EUR`, 'ui.withdraw')],
                },
            ]);
            return;
        }
        setIsButtonDisabled(false);
        const serviceFee = parseFloat(((amount / 100) * SERVICE_FEE_PERCENTAGE).toFixed(2));
        const totalPayout = parseFloat((amount - serviceFee).toFixed(2));
        setServiceFee(serviceFee);
        setTotalPayout(totalPayout);
    }

    async function makeRequest() {
        const amount = form.getFieldValue('amount');
        try {
            await createWithdrawRequest(parseFloat(amount));
            message.success(translate('Withdraw request has been created', 'ui.withdraw'));
            await getUserBalance();
        } catch (err) {}
    }

    return (
        <Wrapper>
            <Container>
                <Card>
                    <Title level={2}>{translate('Withdraw', 'ui.withdraw')}</Title>
                    <Form form={form} initialValues={{ amount: availableBalance.balance }}>
                        {amountToWithdraw < 5 && (
                            <Alert message={translate('minimum amount alert', 'ui.withdraw')} type="warning" closable={false} />
                        )}
                        <Title level={3}>
                            {translate('Available balance to withdraw:', 'ui.withdraw')} {availableBalance.balance} EUR
                        </Title>
                        <Form.Item
                            name="amount"
                            rules={[{ required: true, message: translate('Amount field is required', 'ui.withdraw') }]}
                        >
                            <InputNumber size="large" inputMode="numeric" onChange={(event) => calculatePayout(event)} />
                        </Form.Item>
                        <div>Service fee 8%: {serviceFee}</div>
                        <Title level={4}>
                            {translate('Total payout:', 'ui.withdraw')} {totalPayout}
                        </Title>
                        <UiButton type="primary" size="large" disabled={isButtonDisabled} onClick={makeRequest}>
                            {translate('Create withdraw request', 'ui.withdraw')}
                        </UiButton>
                    </Form>
                </Card>
            </Container>
        </Wrapper>
    );
}
